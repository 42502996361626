import React from 'react'
import styles from './Title.module.scss'

interface ITitleProps {
  title: string
  subtitle: string
}

function Title({ title, subtitle }: ITitleProps) {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subtitle}>{subtitle}</h2>
    </section>
  )
}

export default Title
