import React from 'react'
import styles from './Skills.module.scss'

interface ISkill {
  name: string
  children?: ISkill[]
  description?: string
}

interface ISkillsProps {
  skills: ISkill[]
}

function Skills({ skills }: ISkillsProps) {
  return (
    <ul className={styles.list}>
      {skills.map((item, index) => (
        <li key={`skill_${index}`} className={styles.item}>
          <h4 className={styles.name}>{item.name}</h4>
          {item.children && (
            <ul className={styles._list}>
              {item.children.map((_item, _index) => (
                <li key={`skill_${index}_${_index}`} className={styles._item}>
                  <h5 className={styles._name}>{_item.name}</h5>
                  {_item.children && (
                    <ul className={styles.__list}>
                      {_item.children.map((__item, __index) => (
                        <li key={`skill_${index}_${_index}_${__index}`} className={styles.__item}>
                          {__item.name}
                          {__item.description && <p className={styles.__item_description}>{__item.description}</p>}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Skills
