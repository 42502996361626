import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { CloseOutlined } from '@ant-design/icons'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import styles from './ImageGalleryModal.module.scss'
import './ReactImageGallery.scss'
import ReactImageGallery from 'react-image-gallery'
import Loader from '../Loader/Loader'

Modal.setAppElement('#root')

export interface IImage extends ReactImageGalleryItem {}

interface IImageGalleryModalProps {
  open: boolean
  handleClose: () => void
  images: IImage[]
  startIndex: number
}

function ImageGalleryModal({ open, handleClose, images, startIndex }: IImageGalleryModalProps) {
  const galleryRef = useRef<ReactImageGallery>(null)
  const [pending, setPending] = useState(true)
  let count = 0

  function onImageLoad() {
    count = count + 1
    if (count === images.length) {
      setPending(false)
    }
  }

  function onImageClick(src?: string) {
    window.open(src, 'image-gallery-new-window')
  }

  useEffect(() => {
    setPending(true)
  }, [open])

  return (
    <Modal isOpen={open} onRequestClose={handleClose} className={styles.modal} overlayClassName={styles.overlay}>
      <section className={styles.contents}>
        <button className={styles.close_button} onClick={() => handleClose()}>
          <CloseOutlined />
        </button>
        <ImageGallery
          ref={galleryRef}
          items={images}
          onImageLoad={onImageLoad}
          showFullscreenButton={false}
          showBullets={false}
          showNav={false}
          showPlayButton={false}
          startIndex={startIndex}
          renderCustomControls={() => {
            return (
              images.length > 1 &&
              !pending && (
                <>
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-left-nav"
                    aria-label="Previous Slide"
                    onClick={() => {
                      if (galleryRef.current) {
                        ;(galleryRef.current as any).slideLeft()
                      }
                    }}
                  >
                    <svg
                      className="image-gallery-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="6 0 12 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-right-nav"
                    aria-label="Next Slide"
                    onClick={() => {
                      if (galleryRef.current) {
                        ;(galleryRef.current as any).slideRight()
                      }
                    }}
                  >
                    <svg
                      className="image-gallery-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="6 0 12 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </button>
                </>
              )
            )
          }}
          renderItem={(item) => {
            const { original } = item
            return (
              <div style={{ minHeight: 220 }}>
                {pending && (
                  <div className={styles.loader_wrapper}>
                    <Loader size={100} />
                  </div>
                )}
                <img
                  src={original}
                  className="image-gallery-image"
                  alt=""
                  onClick={() => onImageClick(original)}
                  onLoad={onImageLoad}
                />
              </div>
            )
          }}
        />
      </section>
    </Modal>
  )
}

export default ImageGalleryModal
