import { format, differenceInYears } from 'date-fns'

export function createTags(tags: string[]) {
  return tags.map((tag) => ({
    label: tag
  }))
}

export function getWorkingYears() {
  return differenceInYears(new Date(), new Date(2015, 11, 21))
}

export function getFormatDate() {
  return format(new Date(), 'yyyy. MM')
}
