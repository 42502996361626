import React from 'react'

interface IEmojiProps {
  symbol: string
  label?: string
}

function Emoji({ symbol, label }: IEmojiProps) {
  return (
    <span role="img" aria-label={label ? label : ''} aria-hidden={label ? 'false' : 'true'}>
      {symbol}
    </span>
  )
}

export default Emoji
