import React, { ReactNode } from 'react'
import { LinkOutlined } from '@ant-design/icons'
import styles from './WorkExperience.module.scss'
import Project, { IProject } from './Project'

interface IWorkExperience {
  company: string
  link?: string
  role: string
  description?: string
  timelineText: ReactNode
  projects?: IProject[]
}

interface IWorkExperienceProps {
  workExperiences: IWorkExperience[]
}

function WorkExperience({ workExperiences }: IWorkExperienceProps) {
  return (
    <section>
      <ul className={styles.list}>
        {workExperiences.map((experience, index) => {
          const { timelineText, company, link, role, description, projects } = experience
          return (
            <li className={styles.item} key={`exp_${index}`}>
              <div className={styles.timeline} />
              <div className={styles.timeline_time}>{timelineText}</div>
              <h4 className={styles.company}>{company}</h4>
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer" className={styles.link}>
                  <span>
                    <LinkOutlined />
                    링크
                  </span>
                </a>
              )}
              <span className={styles.company_role}>{role}</span>
              <p className={styles.company_description}>{description}</p>
              <Project projects={projects || []} />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default WorkExperience
