import React from 'react'
import Paper from '../components/Paper/Paper'
import Title from '../components/Title/Title'
import WorkExperience from '../components/WorkExperience/WorkExperience'
import { createTags, getWorkingYears } from '../helpers/resume.helper'
import Paragraph from '../components/Paragraph/Paragraph'
import Section from '../components/Section/Section'
import Emoji from '../components/Emoji/Emoji'
import ContactMe from '../components/ContactMe/ContactMe'
import Skills from '../components/Skills/Skills'

function Resume() {
  return (
    <Paper>
      <Title title={'정현승'} subtitle={'소프트웨어 개발자'} />

      <Paragraph>
        <strong>{getWorkingYears() + 1}년 차 소프트웨어 개발자</strong>입니다. 프론트엔드 개발자로 일하고 있지만,
        서비스를 만든다는 공동의 미션을 가지고 일하기 때문에 관련된 일이라면 포지션을 가리지않습니다. 완성도 높은
        서비스를 개발하고 운영할 수 있는 역량을 갖추기 위해 항상 노력하고 있으며 자유롭고 주도적으로 일할 수 있는
        환경에서 최대의 성과를 냅니다. 열정있는 분들에게 좋은 자극을 많이 받는 편이여서 그런 동료분들과 함께할 수 있는
        기회가 많았으면 좋겠습니다. <Emoji symbol="😍" />
      </Paragraph>

      <Section title={'Work Experience'}>
        <WorkExperience
          workExperiences={[
            {
              company: '우아한형제들',
              link: 'https://www.woowahan.com/',
              role: 'Frontend Engineer',
              timelineText: '2021.01 ~ 재직중 😁'
            },
            {
              company: '카카오헤어샵CIC',
              link: 'https://www.kakaocorp.com/service/KakaoHairshop',
              role: 'Frontend Tech Lead',
              timelineText: '2018.04 ~ 2021.01',
              projects: [
                {
                  title: '카카오헤어샵 웹',
                  link: 'https://hairshop.kakao.com/',
                  description: `카카오톡, 네이티브 앱, 브라우저 등 여러 플랫폼에서 서비스되고 있는 뷰티 예약 플랫폼 카카오헤어샵 서비스입니다.
                    헤어샵, 네일샵을 예약할 수 있고 뷰티 관련 컨텐츠를 제공합니다. 제공되는 컨텐츠들은 카카오헤어샵 플러스 에디터로 제작됩니다.`,
                  timelineText: `2020.04 ~ 2020.08`,
                  tags: createTags(['Typescript', 'React', 'Redux', 'Redux-Saga', 'Next.js', 'SCSS']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592219-bfec5680-1a43-11eb-9c3e-1a37bdb4b859.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592285-ced30900-1a43-11eb-9f85-ec6ff666bf62.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592476-0b066980-1a44-11eb-8b49-f0e5d669501a.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592480-0cd02d00-1a44-11eb-9ccf-64a93a8729ee.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592481-0cd02d00-1a44-11eb-8f03-42fdb94a3201.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592488-0d68c380-1a44-11eb-8d7a-1209cb93dd53.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592493-0e015a00-1a44-11eb-8a11-6c55043d6f60.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592495-0e99f080-1a44-11eb-81d2-04eb0632bccb.jpg'
                    }
                  ]
                },
                {
                  title: '카카오헤어샵 플러스 에디터',
                  description:
                    '카카오헤어샵에서 서비스되고있는 스타일북 매거진을 제작할 수 있는  WYSIWYG 에디터 입니다. 실제 입점되어있는 매장, 메뉴, 디자이너, 스타일 위젯을 컨텐츠에 임베드 할 수 있으며, 유저들은 위젯을 통해 각 서비스 페이지로 랜딩됩니다.',
                  timelineText: '2020.02 ~ 2020.04',
                  tags: createTags(['React', 'CKEditor', 'JSS']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592768-5d478a80-1a44-11eb-8b38-6a5b1cb41a26.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592776-5f114e00-1a44-11eb-823f-3dc22984b98f.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592765-5caef400-1a44-11eb-950b-f730d965f2fa.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592774-5e78b780-1a44-11eb-9d54-99f62b67bb03.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592763-5c165d80-1a44-11eb-8298-022d7a762bf9.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592773-5e78b780-1a44-11eb-964d-d47cdeb2cfe5.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97592760-5ae53080-1a44-11eb-9219-fc2eb2037917.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97592770-5de02100-1a44-11eb-908d-6879532e2ca5.jpg'
                    }
                  ]
                },
                {
                  title: '카카오헤어샵 예약 이벤트 서버',
                  description:
                    '예약이 발생할 때 마다 MQ에 publish 된 매장 별 예약 이벤트를 websocket 인터페이스로 제공해주는 서버입니다. 매장사이드에서 이용하는 카카오헤어샵 for 파트너스 서비스에서 subscribe하여 실시간 예약 현황판을 구현하였습니다.',
                  timelineText: '2019.03 ~ 2019.04',
                  tags: createTags(['Typescript', 'Node.js', 'RabbitMQ', 'Socket.io'])
                },
                {
                  title: '카카오헤어샵 for 파트너스',
                  link: 'http://partners.kakaohairshop.co.kr/',
                  description:
                    '매장사이드에서 사용하는 통합 관리 서비스입니다. 실시간 예약현황판, 예약관리, 입점, 직원관리, 메뉴관리, 정산등의 기능을 제공합니다.',
                  timelineText: '2018.12 ~ 2019.05',
                  tags: createTags(['React', 'Redux', 'Next.js', 'Socket.io', 'SCSS']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593284-e232a400-1a44-11eb-959f-a219b7f25131.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593288-e363d100-1a44-11eb-942d-d1c7a8e2ec3a.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593290-e363d100-1a44-11eb-9e54-f35c8257eea5.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593293-e3fc6780-1a44-11eb-9326-a57a041e4582.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593294-e494fe00-1a44-11eb-859f-0a66de6f7709.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593295-e52d9480-1a44-11eb-89cc-34545f05c369.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593300-e52d9480-1a44-11eb-92f4-83cea858308b.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593302-e5c62b00-1a44-11eb-86ed-6b59fa4fe26c.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593304-e65ec180-1a44-11eb-9504-e00dcd5bf038.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593305-e65ec180-1a44-11eb-93fa-eabb5ab1ffab.jpg'
                    }
                  ]
                },
                {
                  title: '(구) 카카오헤어샵 웹',
                  description: '서비스 개편 전 카카오톡에서 서비스되었던 뷰티 예약 서비스 입니다.',
                  timelineText: '2018.04 ~ 2018.12',
                  tags: createTags(['AngularJS', 'Java', 'Spring Framework']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593821-671dbd80-1a45-11eb-88ec-438ed0cb6c1f.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593829-684eea80-1a45-11eb-8f09-911201337b81.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593831-68e78100-1a45-11eb-9dfa-a62e51744ace.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593834-69801780-1a45-11eb-91a9-d8805aa5db4b.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97593836-69801780-1a45-11eb-98ee-cb220d37a4b5.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97593838-6a18ae00-1a45-11eb-857b-3618b6b2c368.jpg'
                    }
                  ]
                }
              ]
            },
            {
              company: '엔키아',
              link: 'http://www.nkia.co.kr/',
              role: 'Web developer',
              timelineText: '2015.11 ~ 2018.03',
              description: '',
              projects: [
                {
                  title: 'Polestar UI Framework',
                  description: `
                    사내 제품군에 일관된 UI/UX를 적용하기 위해 개발된 리액트 컴포넌트 기반의 디자인 시스템입니다.
                    Button, Menu, Pagination, Layout, Icon 등의 컴포넌트들을 구현했으며 React styleguidist를 이용해
                    컴포넌트 문서화를 했습니다.
                    `,
                  timelineText: '2017.07 ~ 2018.03',
                  tags: createTags(['React', 'LESS']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594128-b368fd80-1a45-11eb-989f-a722d409510a.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594142-b5cb5780-1a45-11eb-9da9-50639041e2d6.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594134-b532c100-1a45-11eb-9ed6-15b605856a87.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594143-b663ee00-1a45-11eb-9765-d726278ebf9d.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594135-b532c100-1a45-11eb-89fe-b1571081533b.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594147-b6fc8480-1a45-11eb-87a2-4553f0fb3a15.jpg'
                    }
                  ]
                },
                {
                  title: 'WebAPM Passive Monitoring',
                  description: `
                    Google analytics와 유사한 서비스로 추적코드(Javscript snippet)를 웹 어플리케이션에 삽입하여 성능 메트릭과 접속 통계를 수집해서 시각화 해주는 서비스 입니다.
                    사용자 데이터를 수집하는 추적코드, 데이터를 수집하는 수집서버, 데이터를 보여주는 대시보드를 개발했습니다.
                    `,
                  timelineText: '2016.11 ~ 2017.06',
                  tags: createTags(['Vanilla Javascript', 'Node.js', 'MongoDB']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594481-122e7700-1a46-11eb-8194-e4e4a3f9000d.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594485-12c70d80-1a46-11eb-94fa-9fac68d11fc7.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594483-122e7700-1a46-11eb-9f9e-5db82caaec39.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594488-12c70d80-1a46-11eb-953a-b50ffa0f604b.jpg'
                    }
                  ]
                },
                {
                  title: 'Polestar WebAPM 2.0',
                  description: `웹 어플리케이션 성능 모니터링 서비스입니다.
                  에이전트가 수집해서 보내온 데이터를 집계하고 관리하는 서버와
                  Data visualization 기능을 제공하는 프론트엔드 어플리케이션을 구현했습니다.
                  대표적인 기능인 HAR(HTTP Archive format) Viewer를 svg로 개발했습니다.
                  `,
                  timelineText: '2015.11 ~ 2016.11',
                  tags: createTags(['Java', 'Spring Framework', 'Node.js', 'MongoDB', 'AngularJS']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594473-1064b380-1a46-11eb-86e3-448d8df08902.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594478-1195e080-1a46-11eb-8619-d905b80a303d.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594476-10fd4a00-1a46-11eb-9309-0def9fd78422.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594480-1195e080-1a46-11eb-9528-aa36c30c417e.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97594467-0e9af000-1a46-11eb-881e-78e92ae0640b.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97594477-10fd4a00-1a46-11eb-8f17-2693e4c4f257.jpg'
                    }
                  ]
                }
              ]
            }
          ]}
        />
      </Section>

      <Section title="Side Project">
        <WorkExperience
          workExperiences={[
            {
              company: '개인 프로젝트',
              timelineText: '2015.01 ~ 😄',
              description: '',
              projects: [
                {
                  title: 'Danta',
                  description: `키움증권 Open API를 이용한 시스템 트레이딩 프로그램입니다. Python으로 키움증권 low level api를 wrapping해서
                  추상화하였고, PyQt로 ui를 제작하였습니다. 기존에 사용하던 몇 가지 트레이딩 기법 알고리즘을 구현해서 특정 조건에 부합하면 매수/매도 포인트에 자동매매합니다.
                  조건검색에 관심종목이 편입하거나 매수/매도 타이밍이 발생하면 디스코드 메신저로 notification을 발생시키고 csv 포맷으로 매매일지를 기록합니다. 
                   `,
                  timelineText: '2020.09 ~ 2020.10',
                  tags: createTags(['Python', 'PyQt', 'Kiwoom API']),

                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97702318-2334c200-1af2-11eb-9eb5-ee9da1e1ec19.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97702330-292aa300-1af2-11eb-8ee4-fb03fd818159.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97600700-ae5b7c80-1a4c-11eb-9093-1f3af91c0e43.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97600877-dfd44800-1a4c-11eb-8d6c-a799c85e7217.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97600713-b1ef0380-1a4c-11eb-9bf2-f8fcb5dfd634.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97600882-e236a200-1a4c-11eb-88a3-a2ffda511878.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97600729-b61b2100-1a4c-11eb-9988-41032a55d229.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97600891-e4006580-1a4c-11eb-91ec-6e7d6d84443c.png'
                    }
                  ]
                },
                {
                  title: 'G-Money Map',
                  link: 'https://gmoney-map.com/',
                  description: `내 주변 경기지역화폐 가맹점을 확인할 수 있는 지도 서비스입니다. 가맹점 데이터가 csv 포맷으로밖에 제공되지 않아서
                  파싱해서 redis에 통째로 넣은뒤 geo query를 통해 서비스하고 있습니다. 
                   `,
                  timelineText: '2020.05 ~ 2020.05',
                  tags: createTags(['React', 'Node.js', 'Express', 'Redis']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97597008-b44f5e80-1a48-11eb-8b36-644b22f2dd0c.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97596787-70f4f000-1a48-11eb-9465-a9690491c6d1.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97596733-5f134d00-1a48-11eb-99ea-45d836014cd5.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97596798-73574a00-1a48-11eb-95f5-de49569eb764.png'
                    }
                  ]
                },
                {
                  title: '마스크사자',
                  link: 'https://masksaja.com/',
                  description: `최대 DAU 40만, 실시간 동접 3만을 달성한 내 주변 공적 마스크 재고 현황 웹 서비스 입니다.
                  공적마스크 API가 공개된 직후 개발을 시작해 그 다음 날 릴리즈까지 마친 프로젝트입니다.
                  네이버 실시간 검색어 1위를 하는 등 예상치 못한 폭발적인 인기를 얻어 개인이 경험하기 힘든 high traffic을 맛볼 수 있었습니다.
                   (현재는 공적마스크 API 지원 중단으로 서비스되고 있지 않습니다.)
                   `,
                  timelineText: '2020.03 ~ 2020.04',
                  tags: createTags(['React', 'Node.js', 'Kakao Map']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97595352-f8416400-1a46-11eb-8149-39073c499896.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97595362-f9729100-1a46-11eb-86dc-6d5b8c08787a.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97595384-fd061800-1a46-11eb-9a26-9ea146906d44.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97595384-fd061800-1a46-11eb-9a26-9ea146906d44.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97595372-faa3be00-1a46-11eb-8a32-e2d7cd11fb7f.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97595374-fb3c5480-1a46-11eb-87fc-a29104f65dbc.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97595399-ff687200-1a46-11eb-9c8b-8c0282203346.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97595401-ff687200-1a46-11eb-91f9-46fb75e77cfe.jpg'
                    }
                  ]
                },
                {
                  title: 'Gravity',
                  description: `위치 기반 소셜 매칭 서비스입니다. 이상형 월드컵과 같은 토너먼트 방식으로 상대방을 선택하거나 선택 받을 수 있습니다.
                     ELO Rating 알고리즘을 적용해 내부 점수 시스템을 구현했습니다. 
                     디자이너 한분, 프론트엔드 개발자 한분과 함께 진행하였고 백엔드를 주로 담당했습니다.`,
                  timelineText: '2019.07 ~ 2019.11',
                  tags: createTags([
                    'Flutter',
                    'Typescript',
                    'Typescript Express Decorator',
                    'MySQL',
                    'Redis',
                    'Elasticsearch'
                  ]),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97595924-8c133000-1a47-11eb-8b9f-075e01a677b2.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97595926-8d445d00-1a47-11eb-80a8-a11e5b1661cb.jpg'
                    }
                  ]
                },
                {
                  title: '만푸',
                  description: `네이버 웹툰, 다음 웹툰, 레진코믹스에 올라오는 모든 웹툰들을 한 곳에서 구독할 수 있게 해주는 서비스 입니다. 
                  구독하고 있는 웹툰의 새로운 회차가 업로드 되면 앱 푸시로 알려줍니다. 다른 개발자 두분과 함께 개발했고 백엔드를 주로 담당했습니다.
                    아쉽게도 현재는 서비스를 종료하였습니다.
                    `,
                  timelineText: '2018.09 ~ 2018.12',
                  tags: createTags(['React Native', 'Typescript', 'Express', 'Java', 'MySQL', 'Redis', 'Selenium']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97596028-a947fe80-1a47-11eb-8fbc-9ad51cc15345.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97596040-ae0cb280-1a47-11eb-96b8-1efa280de8fd.jpg'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97596021-a6e5a480-1a47-11eb-9f58-d534c1f9a488.jpg',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97596026-a8af6800-1a47-11eb-872e-47955e0ca4ea.jpg'
                    }
                  ]
                },
                {
                  title: 'Jukebox',
                  description: `Youtube에서 매일 최신 순위로 갱신되는 뮤직 차트 TOP100 노래들을 재생시켜주는 플레이어입니다.
                    백엔드에서는 최신 인기 음악 순위를 크롤링해서 Youtube API를 이용해 재생목록을 만들고 캐싱합니다.
                    클라이언트는 Youtube player로서 백엔드에서 제공하는 목록을 재생할 수 있습니다.
                    셔플 재생, 내 플레이리스트, 검색 기능을 지원합니다.
                    `,
                  timelineText: '2018.02 ~ 2018.05',
                  tags: createTags(['React', 'Node.js', 'Material UI']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97671762-37fe5f00-1acc-11eb-9edf-19e173c97ff6.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97672906-359d0480-1ace-11eb-9bd1-d2abeceea48c.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97671777-3df44000-1acc-11eb-8a0b-0da96495b32e.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97672909-37ff5e80-1ace-11eb-8d44-51d12132b935.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97671785-4187c700-1acc-11eb-9576-682fa41b5586.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97672917-3afa4f00-1ace-11eb-8d3a-fe2bdc7d1087.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97671800-4482b780-1acc-11eb-9b3e-4d396c00172d.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97672919-3cc41280-1ace-11eb-9e3c-273397bc703f.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97671882-6b40ee00-1acc-11eb-81c0-d698d9845264.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97672927-3f266c80-1ace-11eb-994b-1c88aae5a875.png'
                    }
                  ]
                },
                {
                  title: 'Sprinkler',
                  description:
                    '실시간 검색어 기반으로 각종 뉴스를 TextRank 알고리즘을 이용해 요약한 뒤 블로그에 자동포스팅 해주는 웹 오토메이션 프로젝트입니다. Google Adsense로 수익실현까지 한 재미있는 경험이었습니다.',
                  timelineText: '2018.01 ~ 2018.04',
                  tags: createTags(['Node.js', 'Python', 'Selenium', 'WebdriverIO'])
                },
                {
                  title: '개발 블로그',
                  link: 'https://ahribori.com',
                  description: `모던 웹 생태계를 공부하면서 개발한 블로그입니다. 
                  하드웨어 조립부터 네트워크 구성, 백엔드, 프론트엔드, 배포, 운영까지 해보았고 공부한 내용을 정리하는 취지로 포스팅했습니다. 
                  홈 서버에서 운영하고 있는데 점점 게을러져서 방치중입니다.
                  `,
                  timelineText: '2017.01 ~ 2017.04',
                  tags: createTags(['Node.js', 'MongoDB', 'Redis', 'React']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97694903-946e7800-1ae6-11eb-8edf-34e350c020a1.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97695012-ba941800-1ae6-11eb-8980-53d34d3e9cc8.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97694918-989a9580-1ae6-11eb-9108-23d46942f83f.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97695015-be279f00-1ae6-11eb-9bb6-d8cf7dfd605a.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97694936-9d5f4980-1ae6-11eb-8df8-2332dc149669.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97695027-c1bb2600-1ae6-11eb-8b3b-20645af67a1e.png'
                    }
                  ]
                },
                {
                  title: 'Auth Server & Javascript SDK',
                  link: 'https://ahribori.github.io/jwt_auth_server',
                  description: `사이드 프로젝트를 할 때
                  User Authentication & Authorization을 반복적으로 만들지 않기 위해 만든 인증 서버입니다.
                  로그인 기능을 쉽게 붙힐 수 있게 Javascript SDK도 개발했습니다.
                  `,
                  timelineText: '2017.01 ~ 2017.04',
                  tags: createTags(['Node.js', 'MongoDB', 'Redis', 'React']),
                  images: [
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97701260-79086a80-1af0-11eb-8e52-27481cc710ca.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97701302-8aea0d80-1af0-11eb-943d-dcd9eb4c4dd4.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97701263-7ad22e00-1af0-11eb-9454-259ee67b0383.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97701307-8cb3d100-1af0-11eb-8d32-24c2b5316a8c.png'
                    },
                    {
                      original:
                        'https://user-images.githubusercontent.com/10773118/97701268-7d348800-1af0-11eb-848f-c0d785f5dd52.png',
                      thumbnail:
                        'https://user-images.githubusercontent.com/10773118/97701311-8e7d9480-1af0-11eb-868a-cc0348829036.png'
                    }
                  ]
                }
              ],
              role: ''
            }
          ]}
        />
      </Section>

      <Section title={'Skills'}>
        <Skills
          skills={[
            {
              name: 'Frontend',
              children: [
                {
                  name: 'Language',
                  children: [
                    {
                      name: 'HTML'
                    },
                    {
                      name: 'CSS'
                    },
                    {
                      name: 'SVG/Canvas'
                    },
                    {
                      name: 'Javascript'
                    },
                    {
                      name: 'Typescript'
                    }
                  ]
                },
                {
                  name: 'Framework/Library',
                  children: [
                    {
                      name: 'React'
                    },
                    {
                      name: 'Vue'
                    },
                    {
                      name: 'Angular'
                    }
                  ]
                },
                {
                  name: 'State Management',
                  children: [
                    {
                      name: 'Redux (with thunk, saga, observable)'
                    },
                    {
                      name: 'MobX'
                    }
                  ]
                },
                {
                  name: 'Preprocessor',
                  children: [
                    {
                      name: 'Webpack'
                    },
                    {
                      name: 'Babel'
                    },
                    {
                      name: 'LESS, SCSS, PostCSS'
                    }
                  ]
                },
                {
                  name: 'Test/Automation',
                  children: [
                    {
                      name: 'Jest'
                    },
                    {
                      name: 'Enzyme'
                    },
                    {
                      name: 'Mocha'
                    },
                    {
                      name: 'Selenium'
                    },
                    {
                      name: 'WebdriverIO'
                    },
                    {
                      name: 'Puppeteer'
                    }
                  ]
                }
              ]
            },
            {
              name: 'Backend',
              children: [
                {
                  name: 'Language',
                  children: [
                    {
                      name: 'Node.js (with Typescript)'
                    },
                    {
                      name: 'Python'
                    },
                    {
                      name: 'Java'
                    }
                  ]
                },
                {
                  name: 'Framework',
                  children: [
                    {
                      name: 'Express'
                    },
                    {
                      name: 'Nest'
                    },
                    {
                      name: 'Spring'
                    }
                  ]
                },
                {
                  name: 'Database',
                  children: [
                    {
                      name: 'MySQL'
                    },
                    {
                      name: 'MongoDB'
                    },
                    {
                      name: 'ElasticSearch'
                    },
                    {
                      name: 'Redis'
                    }
                  ]
                },
                {
                  name: 'Deploy/Operation',
                  children: [
                    {
                      name: 'Kubernetes'
                    },
                    {
                      name: 'Mesos'
                    },
                    {
                      name: 'Marathon'
                    },
                    {
                      name: 'Docker compose/swarm'
                    }
                  ]
                }
              ]
            }
          ]}
        />
      </Section>

      <Section title={'Contact'}>
        <ContactMe />
      </Section>
    </Paper>
  )
}

export default Resume
