import React from 'react'
import styles from './Paper.module.scss'

interface IPaperProps {
  children?: React.ReactNode
}

function Paper({ children }: IPaperProps) {
  return <article className={styles.paper}>{children}</article>
}

export default Paper
