import particlesNasa from '../../assets/particles/nasa.json'
import ReactParticles, { IParticlesParams } from 'react-particles-js'
import styles from './Particles.module.scss'
import React from 'react'

function Particles() {
  // const particles = [particlesNasa, particlesSnow]
  // const randomParticleIndex = Math.round(Math.random())

  return (
    <ReactParticles
      className={styles.particles}
      width="100%"
      height="100%"
      params={particlesNasa as IParticlesParams}
    />
  )
}

export default Particles
