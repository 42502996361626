import { useEffect, useState } from 'react'

const images = [
  'https://user-images.githubusercontent.com/10773118/97591007-87984880-1a42-11eb-9e2b-cc12e401155b.jpg',
  'https://user-images.githubusercontent.com/10773118/97591009-8830df00-1a42-11eb-8f47-63147b6a2adb.jpg',
  'https://user-images.githubusercontent.com/10773118/97591012-8830df00-1a42-11eb-85c3-ccf4f6bb71a0.jpg',
  'https://user-images.githubusercontent.com/10773118/97591016-88c97580-1a42-11eb-93de-433e662aa618.jpg',
  'https://user-images.githubusercontent.com/10773118/97591021-89620c00-1a42-11eb-981d-bbb398d53611.jpg',
  'https://user-images.githubusercontent.com/10773118/97591026-89faa280-1a42-11eb-80aa-e43545e19a9c.jpg',
  'https://user-images.githubusercontent.com/10773118/97591027-8a933900-1a42-11eb-828e-9090c17dcd1a.jpg'
]

export default function useBackgroundImage() {
  const [imageIndex, setImageIndex] = useState(-1)
  const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false)

  function getRandomImageIndex() {
    return Math.round(Math.random() * (images.length - 1))
  }

  useEffect(() => {
    console.log(imageIndex, images[imageIndex])
    const imageUrl = imageIndex >= 0 ? images[imageIndex] : ''

    let image: HTMLImageElement

    function loadImageAsync() {
      return new Promise((resolve) => {
        image = new Image()
        image.src = imageUrl
        image.onload = onImageLoaded

        if (image.complete) {
          onImageLoaded()
        }

        function onImageLoaded() {
          document.body.style.backgroundImage = `url(${imageUrl})`
          document.getElementById('loader')?.remove()
          setBackgroundImageLoaded(true)
          resolve()
        }
      })
    }

    if (imageIndex < 0) {
      setImageIndex(getRandomImageIndex())
    } else {
      loadImageAsync()
        .then(() => {})
        .catch(console.error)
    }

    return () => {
      if (image) {
        image.onload = null
      }
    }
  }, [imageIndex])

  useEffect(() => {
    function changeBackgroundImage() {
      setImageIndex(getRandomImageIndex())
    }

    let interval = setInterval(changeBackgroundImage, 20000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  return backgroundImageLoaded
}
