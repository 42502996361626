import React from 'react'
import './App.scss'
import useBackgroundImage from './hooks/useBackgroundImage'
import Particles from './components/Particles/Particles'
import Resume from './pages/Resume'
import ImageGalleryModalProvider from './contexts/ImageGalleryModalContext'

function App() {
  const backgroundImageLoaded = useBackgroundImage()

  if (!backgroundImageLoaded) {
    return null
  }

  return (
    <div className="App">
      <Particles />
      <ImageGalleryModalProvider>
        <Resume />
      </ImageGalleryModalProvider>
    </div>
  )
}

export default App
