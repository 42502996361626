import React, { ReactNode } from 'react'
import styles from './Section.module.scss'
interface ISectionProps {
  title?: string
  children: ReactNode
}

function Section({ title, children }: ISectionProps) {
  return (
    <section className={styles.section}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {children}
    </section>
  )
}

export default Section
