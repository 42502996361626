import React from 'react'

interface ILoaderProps {
  size: number
  stroke?: number
}

function Loader({ size, stroke }: ILoaderProps) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: 'auto',
          background: 'transparent',
          display: 'block',
          shapeRendering: 'auto'
        }}
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx={50}
          cy={50}
          r={32}
          strokeWidth={stroke || 5}
          stroke="#b47cff"
          strokeDasharray="50.26548245743669 50.26548245743669"
          fill="none"
          strokeLinecap="round"
          transform="rotate(357.571 49.9996 49.9997)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
          />
        </circle>
      </svg>
    </div>
  )
}

export default Loader
