import React from 'react'
import { MailOutlined, CodeOutlined, GithubOutlined, LinkedinOutlined } from '@ant-design/icons'
import styles from './ContactMe.module.scss'

function ContactMe() {
  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <MailOutlined />
          <a href="mailto:ahribori@gmail.com" target="_blank" rel="noopener noreferrer">
            이메일
          </a>
        </li>
        <li className={styles.item}>
          <CodeOutlined />
          <a href="https://ahribori.com" target="_blank" rel="noopener noreferrer">
            블로그
          </a>
        </li>
        <li className={styles.item}>
          <GithubOutlined />
          <a href=" https://github.com/ahribori" target="_blank" rel="noopener noreferrer">
            깃허브
          </a>
        </li>
        <li className={styles.item}>
          <LinkedinOutlined />
          <a href="https://www.linkedin.com/in/ahribori-729931161" target="_blank" rel="noopener noreferrer">
            링크드인
          </a>
        </li>
      </ul>
    </section>
  )
}

export default ContactMe
