import React, { ReactNode } from 'react'
import styles from './Paragraph.module.scss'

interface IParagraphProps {
  children: ReactNode
}

function Paragraph({ children }: IParagraphProps) {
  return <p className={styles.paragraph}>{children}</p>
}

export default Paragraph
