import React, { ReactNode, useState } from 'react'
import ImageGalleryModal, { IImage } from '../components/ImageGalleryModal/ImageGalleryModal'

interface IImageGalleryModalContextValues {
  openImageGalleryModal: (images: IImage[], index?: number) => void
}

const defaultValues: IImageGalleryModalContextValues = {
  openImageGalleryModal: () => null
}

export const ImageGalleryModalContext = React.createContext(defaultValues)

interface IImageGalleryModalProviderProps {
  children: ReactNode
}

export default function ImageGalleryModalProvider({ children }: IImageGalleryModalProviderProps) {
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState<IImage[]>([])
  const [startIndex, setStartIndex] = useState(0)

  function openImageGalleryModal(images: IImage[], index?: number) {
    setOpen(true)
    setImages(() => images)
    setStartIndex(index || 0)
  }

  function closeImageGalleryModal() {
    setOpen(false)
    setImages(() => [])
  }

  const contextValues: IImageGalleryModalContextValues = {
    openImageGalleryModal
  }

  return (
    <ImageGalleryModalContext.Provider value={contextValues}>
      {children}
      <ImageGalleryModal open={open} handleClose={closeImageGalleryModal} images={images} startIndex={startIndex} />
    </ImageGalleryModalContext.Provider>
  )
}
