import React from 'react'
import styles from './Tag.module.scss'

export interface ITagProps {
  label: string
}

function Tag({ label }: ITagProps) {
  return <span className={styles.tag}>{label}</span>
}

export default Tag
