import React, { useContext } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { LinkOutlined } from '@ant-design/icons'
import '../../styles/lib/react-lazy-load-image-component.scss'
import styles from './Project.module.scss'
import Tag, { ITagProps } from './Tag'
import { ImageGalleryModalContext } from '../../contexts/ImageGalleryModalContext'
import { IImage } from '../ImageGalleryModal/ImageGalleryModal'
import { getPlaceholderSrc } from '../../helpers/image.helper'

export interface IProject {
  title: string
  link?: string
  description?: string
  timelineText: string
  tags?: ITagProps[]
  images?: IImage[]
}

interface IProjectProps {
  projects: IProject[]
}

function Project({ projects }: IProjectProps) {
  const { openImageGalleryModal } = useContext(ImageGalleryModalContext)

  return (
    <ul className={styles.list}>
      {projects.map((project, index) => {
        const { timelineText, title, link, description, tags, images } = project
        return (
          <li className={styles.item} key={`project_${index}`}>
            <div className={styles.timeline}>
              <div className={styles.timeline_time}>{timelineText}</div>
              <h5 className={styles.project_title}>{title}</h5>
              {link && (
                <a href={link} target="_blank" rel="noopener noreferrer" className={styles.project_link}>
                  <span>
                    <LinkOutlined />
                    링크
                  </span>
                </a>
              )}
              <p className={styles.project_description}>{description}</p>
              <div className={styles.tags_container}>
                {tags?.map((tag, index) => {
                  const { label } = tag
                  return <Tag key={`tag_${label}_${index}`} label={label} />
                })}
              </div>
              {images && (
                <ul className={styles.image_list}>
                  {images.map((image, index) => {
                    return (
                      <li key={`image_${index}`} className={styles.image_item}>
                        <LazyLoadImage
                          effect={'blur'}
                          alt={'imageAlt'}
                          placeholderSrc={getPlaceholderSrc()}
                          src={image.thumbnail}
                          className={styles.image}
                          onClick={() => openImageGalleryModal(images || [], index)}
                        />
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default Project
